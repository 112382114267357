.current {
  color: green;
} 

body {
  margin: 0 auto;
  max-width: 700px;
  padding: 20px;
  font-family: sans-serif;
  background-color: #000000;
  color: #CCCCCC;
}

a {
  text-decoration: none;
  color: #ED1B25;
}

a:visited {
  color: #ED1B25;
}

a:hover {
  text-decoration: none;
  color: #000000;
  background-color: #ED1B25;
}

body,
input,
button {
  font-family: "Noto Serif", serif;
  font-size: 20px;
}

hr,
h1,
h2 {
  margin-top: 60px;
  color: #FF0000;
}

hr {
  border: 0;
}

li {
  padding: 5px 0;
}

pre {
  font-size: 15px;
  overflow-x: auto;
}

pre,
blockquote {
  margin-bottom: 40px;
}

p img,
  table {
width: 100%;
  }

code {
  color: #FFFFFF;
}

p {
clear: both;
}

.avatar {
  width: 60px;
  heigth: 60px;
  border-radius: 60px;
}

.left {
  float: left;
  margin: 8px 24px 24px 0;
}

.quote {
  text-indent: -16px;
  font-size: 32px;
  line-height: 40px;
}

.button,
.email {
  -webkit-appearance: none;
  color: #000000;
  padding: 15px;
}

.email {
  background-color: #ffffff;
  border: solid 1px #888888;
}

.button {
  background-color: #ffc469;
  border: solid 1px #ffc469;
  font-weight: bold;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
